import React, { useState } from 'react';
import styled from 'styled-components/macro';
import './media.css';
import oneCanShort from '../../media/one-can-short.jpg';

const MediaPreviewContainer = styled.div`
  text-align:center;
  display:inline-block;
  margin: 0 25px;  
`;

const MediaPreview = styled.div`
  display:inline-block;
  width:250px;
  background-color:black;
  border: 1px solid white;
  cursor: pointer;
  margin: 15px;
  
  img {
    width:100%;
  }
`;

const BackWrapper = styled.div`
  text-align: center;
`;

const BackButton = styled.a`
  margin: 10px;
  cursor: pointer;
  
  text-decoration:none;
  color:#FBFBF3;
  text-transform:capitalize;
  font-size:32px;
`;

const SpotifyLink = styled.div`
  text-align: center;
  img {
    width:300px;
  }
`;

const SongTitle = styled.div`
  color:#5C7CA1;
  padding:10px;
`

const H1 = styled.h1`
  text-align:center;
  margin: 10px;
`;

function Media({ bandData }) {
  const [selectedBandCampSong, setSelectedBandCampSong] = useState(false);
console.log("selectedBandCampSong : ", selectedBandCampSong);
  const mapBandCampSongs = () => {
    return bandData.bandCamp.map(bandCampSong => {
      return (
        <MediaPreview onClick={() => setSelectedBandCampSong(bandCampSong)}>
          <>
            <img src={`/song-art/${bandCampSong.thumbnail}`} />
            <SongTitle>{bandCampSong.title}</SongTitle>
          </>
        </MediaPreview>
      );
    });
  };

  return (
    <div className="main-default-container">
      {!selectedBandCampSong &&
        <div className="default-content-container">

          <H1>Bandcamp</H1>
          <MediaPreviewContainer>
            {mapBandCampSongs()}
          </MediaPreviewContainer>
          <H1>Spotify</H1>
          <SpotifyLink>
            <a href='https://open.spotify.com/album/30eCCGfzmeu5iZL0kPkaep?si=fwzjqQX_ReSh8UqJqCM_Hg' target="_blank">
              <img className="one-can-short" src={oneCanShort}/>
            </a>
          </SpotifyLink>
        </div>
      }
      {selectedBandCampSong &&
        <>
          <div
            className='video'
            style={{
              position: "relative",
              paddingBottom: "56.25%" /* 16:9 */,
              paddingTop: 25,
              height: 0,
          }}>
            <div style={{
              top:0,
              left:0,
              right:0,
              bottom:0,
              display: 'inline-block',
              position: 'absolute',
            }}>
              <iframe
                style={{border: "0", width: "100%", height: "120px", marginLeft:'25px;'}}
                src={`https://bandcamp.com/EmbeddedPlayer/track=${selectedBandCampSong.tractId}/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/artwork=small/transparent=true/`}
                seamless
              >
                <a href={selectedBandCampSong.url}>{selectedBandCampSong.title}</a>
              </iframe>

              <BackWrapper>
                <BackButton onClick={() => setSelectedBandCampSong(false)}>back</BackButton>
              </BackWrapper>
            </div>

          </div>
        </>
      }

    </div>
  );

}

export default Media;